import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class BackButton extends Component {
  prevPath = (path) => {
    this.props.history.push(path);
  };

  render() {
    return (
      <button
        type="button"
        className="trans-btn mr-r10"
        onClick={() => this.prevPath(this.props.getPath)}
      >
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.33333 2.86765e-05C8.66437 -0.00183006 8.98849 0.0915604 9.26435 0.268294C9.54022 0.445028 9.75534 0.697096 9.88228 0.992364C10.0092 1.28763 10.0422 1.61272 9.97713 1.92618C9.91201 2.23965 9.75171 2.52728 9.51667 2.75241L4.01667 8.04794L9.51667 13.3435C9.78971 13.6514 9.93239 14.0475 9.91618 14.4525C9.89998 14.8576 9.7261 15.2419 9.42927 15.5286C9.13245 15.8152 8.73455 15.9832 8.31509 15.9988C7.89563 16.0144 7.4855 15.8767 7.16667 15.613L0.499999 9.17464C0.18958 8.87307 0.015344 8.46512 0.0153439 8.03989C0.0153439 7.61466 0.18958 7.20671 0.499999 6.90513L7.16667 0.466808C7.4771 0.169448 7.8961 0.00180636 8.33333 2.86765e-05Z"
            fill="#00AEEF"
          />
          <path
            d="M1.66667 6.43835L18.3333 6.43835C18.7754 6.43835 19.1993 6.60793 19.5118 6.90979C19.8244 7.21164 20 7.62105 20 8.04793C20 8.47482 19.8244 8.88422 19.5118 9.18608C19.1993 9.48793 18.7754 9.65752 18.3333 9.65752L1.66667 9.65752C1.22464 9.65752 0.800716 9.48794 0.488155 9.18608C0.175594 8.88423 -6.57872e-07 8.47482 -6.95192e-07 8.04794C-7.32511e-07 7.62105 0.175594 7.21164 0.488155 6.90979C0.800716 6.60793 1.22464 6.43835 1.66667 6.43835Z"
            fill="#00AEEF"
          />
        </svg>
        &nbsp;
      </button>
    );
  }
}

export default withRouter(BackButton);
